import React, { useState, useEffect } from 'react';
import {
  HamburgerMenuBar,
  HamburgerMenu,
  MenuNavigationContent,
  MenuItemContainer,
  MenuNavigation,
  MenuNavigationBackground,
  MenuItem,
} from './styles';
import { useAnimation } from 'framer-motion';
// import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { navigate } from 'gatsby';


const Menu = ({
  items = [
    { title: 'À propos', handle: '/a-propos', is_external: false },
    {
      title: 'Activités éducatives',
      handle:
        'https://education.banq.qc.ca/en-classe/en-classe-secondaire/activites-cles-en-main-secondaire/lignes-du-temps-du-quebec/',
      is_external: true,
    },
    {
      title: 'Peuples autochtones',
      handle: '/peuples-autochtones',
      is_external: false,
    },
    {
      title: 'Partenaires',
      handle: '/partenaires',
      is_external: false,
    },
    {
      title: "Conditions d'utilisation",
      handle:
        "https://www.banq.qc.ca/outils/conditions_generales_dutilisation/#:~:text=L'usager%20est%20responsable%20de,aucune%20responsabilit%C3%A9%20%C3%A0%20cet%20%C3%A9gard",
      is_external: true,
    },
    { title: 'Aide', handle: '/aide', is_external: false },
  ],
  location,
}) => {
  const [navOpen, setNavOpen] = useState(false);
  // useDisableBodyScroll(navOpen);
  const controls = useAnimation();

  useEffect(() => {
    if (navOpen) {
      controls.start('animate');
    }
  }, [navOpen]);

  useEffect(() => {
    controls.start('initial');
    setNavOpen(false);
  }, [location.pathname]);

  const variants1 = {
    initial: {
      x: '-50%',
      rotate: '0',
    },
    animate: {
      rotate: '45deg',
      x: '-50%',
      y: '-50%',
      top: '50%',
      transition: { type: 'spring', stiffness: 150 },
    },
  };

  const variants2 = {
    initial: {
      x: '-50%',
      rotate: '0deg',
    },
    animate: {
      rotate: '-45deg',
      x: '-50%',
      y: '-50%',
      top: '50%',
      transition: { type: 'spring', stiffness: 150 },
    },
  };

  const variantMenuNavigationBackground = {
    initial: {
      scaleY: 0,
      opacity: 0,
    },
    animate: {
      scaleY: 1,
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  const variantMenuNavigationContent = {
    initial: {
      opacity: 0,
      transition: {
        duration: 1.5,
        delayChildren: 0.2,
        staggerChildren: 0.2,
        staggerDirection: -1,
        onComplete: () => {
          if (!navOpen) controls.start('initial');
        },
      },
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1.5,
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  const variantsMenuItemContainer = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  };

  return (
    <>
      <div className='filtredResult'></div>
      <HamburgerMenu
        isLight={location.pathname.includes('evenement')}
        navOpen={navOpen}
        onClick={() => setNavOpen(!navOpen)}
      >
        <HamburgerMenuBar
          isLight={location.pathname.includes('evenement')}
          style={{ top: 0, left: '50%' }}
          variants={variants1}
          animate={navOpen ? 'animate' : 'initial'}
        />
        <HamburgerMenuBar
          isLight={location.pathname.includes('evenement')}
          style={{
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
            display: navOpen ? 'none' : 'block',
          }}
        />
        <HamburgerMenuBar
          isLight={location.pathname.includes('evenement')}
          style={{ bottom: 0, left: '50%' }}
          variants={variants2}
          animate={navOpen ? 'animate' : 'initial'}
        />
      </HamburgerMenu>
      <MenuNavigation navOpen={navOpen}>
        <MenuNavigationBackground
          navOpen={navOpen}
          animate={controls}
          variants={variantMenuNavigationBackground}
        >
          {items?.length > 0 ? (
            <MenuNavigationContent
              animate={navOpen ? 'animate' : 'initial'}
              variants={variantMenuNavigationContent}
            >
              {items.map((item, index) => {
                return (
                  <MenuItemContainer
                    key={`${item?.handle}--${index}`}
                    variants={variantsMenuItemContainer}
                  >
                    <MenuItem
                      onClick={() => {
                        if (item?.is_external) {
                          window?.open(item?.handle);
                        } else {
                          navigate(item?.handle);
                        }

                        setNavOpen(false);
                      }}
                    >
                      {item?.title}
                      {item?.is_external && (
                        <svg
                          style={{ 
                            marginLeft: '20px'
                           }}
                          xmlns='http://www.w3.org/2000/svg'
                          height='24px'
                          viewBox='0 0 24 24'
                          width='24px'
                          fill='#ffffff'
                        >
                          <path d='M0 0h24v24H0V0z' fill='none' />
                          <path d='M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z' />
                        </svg>
                      )}
                    </MenuItem>
                  </MenuItemContainer>
                );
              })}
            </MenuNavigationContent>
          ) : (
            <></>
          )}
        </MenuNavigationBackground>
      </MenuNavigation>
    </>
  );
};

export default Menu;
